var communiacsModules = ( function( communiacsModules ) {
  'use strict';

  if(!$) {
    throw new Error('jQuery is required.');
  }

  var moreMenuActive,
    mobileNavActive,
    minWindowWidth,
    navMetaBreakpoint,
    navMetaMoved = 0,
    moreMenuIcon = '<div class="more-icon"><span class="bubble"></span><span class="bubble"></span><span class="bubble"></span></div><div class="more-text"><span>' + $('.page__header').data('more-button-label') + '</span></div>',
    mobileMenuIcon = '<div class="menu-icon"><span class="bubble"></span><span class="bubble"></span><span class="bubble"></span></div>';

  function checkNavActiveState() {
    if (!$('.module-nav--main .module-nav__item--active').length) {
      $('.module-nav--main').addClass('-no-active');
    }
  }

  function setNavMetaBreakpoint() {
    if(!navMetaMoved) {
      var maxWidthLimited = $(window).width() - $('.page__topbar .inner__wrap').width();
      var navLangWidth = $('.page__topbar .module-nav--lang').width();
      var navMetaWidth = $('.page__topbar .module-nav--meta').width();
      var safetyMargin = 100;

      navMetaBreakpoint = maxWidthLimited + navLangWidth + navMetaWidth + safetyMargin;
    }
  }

  function initDesktopMenu() {
    $('.module-nav--main .module-nav__lvl1 > .module-nav__item.module-nav__item--hasSubpages > a').unbind('mousedown').unbind('click');
    // Add the submenu events
    $('.module-nav--main .module-nav__lvl1 > .module-nav__item.module-nav__item--hasSubpages > a').bind('click', function() {
      if($('html').hasClass('touchevents')) {
        return false;
      }
    }).bind('mousedown', function() {
      if($('html').hasClass('touchevents')) {
        if($(this).closest('.module-nav__item').hasClass('-opened')) {
          $(this).closest('.module-nav__item').removeClass('-opened');
        }else{
          $(this).closest('.module-nav__item').siblings().removeClass('-opened');
          $(this).closest('.module-nav__item').addClass('-opened');
        }
      }
    });
  }

  function initMobileMenu() {
    $('.module-nav--more .module-nav__item.module-nav__item--hasSubpages > a').unbind('mousedown').unbind('click');

    // Add the submenu events
    $('.module-nav--more .module-nav__lvl1 > .module-nav__item.module-nav__item--hasSubpages > a').bind('click', function() {
      if($(this).closest('.module-nav__item').find('> ul').length) {
        return false;
      }
    }).bind('mousedown', function() {
      if($(this).closest('.module-nav__item').find('> ul').length) {
        if($(this).closest('.module-nav__item').hasClass('-opened')) {
          $(this).closest('.nav__container').removeClass('-submenu');
          $(this).closest('.module-nav__item').removeClass('-opened');

          if($('.module-nav-more-btn-back').length) {
            $('.module-nav-more-btn-back').trigger('mousedown');
          }
        }else{
          $(this).closest('.module-nav__item').siblings().removeClass('-opened');
          $(this).closest('.nav__container').addClass('-submenu');
          $(this).closest('.module-nav__item').addClass('-opened');

          if($('.-nav-mobile').length) {
            addNavBtnBack();
          }
        }
      }
    });

    $('.module-nav--more .module-nav__item.module-nav__item--active.module-nav__item--hasSubpages > a').trigger('mousedown');
    if($('.module-nav-more-btn-back').length) {
      removeNavBtnBack();
    }
  }

  function resizeNav() {

    if($('body').hasClass('-nav-desktop-cropped')) {
      var menuSpace = $('.page__header > .inner__wrap').width() - $('.page__header > .inner__wrap > .module-logo').outerWidth() - $('.page__header > .inner__wrap > .more-button').outerWidth();
    } else {
      var menuSpace = $('.page__header > .inner__wrap').width() - $('.page__header > .inner__wrap > .module-logo').outerWidth() - $('.page__header > .inner__wrap > .module-search').width();
    }
    $('.module-nav--main').css('width', menuSpace);
    var navLvl1Items = $('.module-nav--main .module-nav__lvl1 > .module-nav__item');

    if((navMetaBreakpoint >= $(window).width()) && $('.module-nav--meta').length && !navMetaMoved) {
      if(!moreMenuActive) {
        appendMoreMenu();
      }

      $('.module-nav--meta').appendTo($('.module-nav--more .nav__container'));
      navMetaMoved = 1;
    }

    if((getNavWidth(navLvl1Items) >= $('.module-nav--main').width()) && navLvl1Items.length > 3) {
      // NavItems too big > Move it to "More"
      if(!moreMenuActive) {
        clearBodyNavClasses();
        $('body').addClass('-nav-desktop-cropped');
        appendMoreMenu();
      }

      var widthBeforeMove = $('.module-nav--main .module-nav__lvl1 > .module-nav__item:last-child').outerWidth();
      $('.module-nav--main .module-nav__lvl1 > .module-nav__item:last-child').data('width-before-move', widthBeforeMove);
      $('.module-nav--main .module-nav__lvl1 > .module-nav__item:last-child').prependTo($('.module-nav--more .module-nav__lvl1'));
      initMobileMenu();

      resizeNav();
    }

    if($('body').hasClass('-nav-desktop-cropped')) {
      menuSpace = $('.page__header > .inner__wrap').width() - $('.page__header > .inner__wrap > .module-logo').outerWidth() - $('.page__header > .inner__wrap > .more-button').outerWidth();
    } else {
      menuSpace = $('.page__header > .inner__wrap').width() - $('.page__header > .inner__wrap > .module-logo').outerWidth() - $('.page__header > .inner__wrap > .module-search').width();
    }
    $('.module-nav--main').css('width', menuSpace);
    if(navLvl1Items.length && navLvl1Items.length <= 3 && getNavWidth(navLvl1Items) >= $('.module-nav--main').width() && !mobileNavActive) {
      // NavItems too big and too few (<= 3) > Move everything to "More" and switch to mobile view
      clearBodyNavClasses();
      $('body').addClass('-nav-mobile');
      minWindowWidth = $(window).width();

      if(!moreMenuActive) {
        appendMoreMenu();
      }

      var elCount = $('.module-nav--main .module-nav__lvl1 > .module-nav__item').length;
      for(var j=0; j < elCount; j++) {
        var widthBeforeMove = $('.module-nav--main .module-nav__lvl1 > .module-nav__item:last-child').outerWidth();
        $('.module-nav--main .module-nav__lvl1 > .module-nav__item:last-child').data('width-before-move', widthBeforeMove);
        $('.module-nav--main .module-nav__lvl1 > .module-nav__item:last-child').prependTo($('.module-nav--more .module-nav__lvl1'));
      }
      initMobileMenu();

      $('.page__header > .inner__wrap > .more-button').html(mobileMenuIcon);
      $('.page__topbar .module-nav--lang').prependTo($('.page__header > .inner__wrap'));
      initLanguageNavMobile();

      if($('.module-nav--meta').length && !navMetaMoved) {
        $('.module-nav--meta').appendTo($('.module-nav--more .nav__container'));
        navMetaMoved = 1;
      }

      if($('.module-nav--more').hasClass('-open') && $('.module-nav--more .module-nav__lvl1 > .module-nav__item.-opened').length) {
        addNavBtnBack();
      }

      mobileNavActive = 1;
    }else{
      if(mobileNavActive) {
        if(minWindowWidth < $(window).width()) {
          // if mobile nav active and current window width is bigger than minWindowWidth
          // move back 3 menu items
          clearBodyNavClasses();
          $('body').addClass('-nav-desktop-cropped');

          $('.page__header > .inner__wrap > .more-button').html(moreMenuIcon);
          if($('.module-nav-more-btn-back').length) {
            $('.module-nav-more-btn-back').trigger('mousedown');
          }
          $('.page__header .module-nav--lang').prependTo($('.page__topbar .inner__wrap'));
          if(navMetaBreakpoint < $(window).width()) {
            $('.page__header .module-nav--meta').appendTo($('.page__topbar .inner__wrap'));
            navMetaMoved = 0;
          }
          initLanguageNavDesktop();

          for(var i = 0; i < 3; i++) {
            $('.module-nav--more .module-nav__lvl1 > .module-nav__item:first-child > a').removeAttr('style').unbind('click').unbind('mousedown');
            $('.module-nav--more .module-nav__lvl1 > .module-nav__item:first-child').removeClass('hover').removeClass('-opened');
            $('.module-nav--more .module-nav__lvl1 > .module-nav__item:first-child > ul').removeAttr('style');
            $('.module-nav--more .module-nav__lvl1 > .module-nav__item:first-child').appendTo($('.module-nav--main .module-nav__lvl1'));
          }
          initDesktopMenu();
          mobileNavActive = 0;

          resizeNav();
        }
      }else{
        if($('.module-nav--more .module-nav__lvl1 > .module-nav__item').length) {
          clearBodyNavClasses();
          $('body').addClass('-nav-desktop-cropped');


          var widthBeforeMove = $('.module-nav--more .module-nav__lvl1 > .module-nav__item:first-child').data('width-before-move');

          if(getNavWidth(navLvl1Items) + widthBeforeMove < $('.module-nav--main').width()) {
            // if last moved menu item has enough space in desktop menu, move it back
            $('.module-nav--more .module-nav__lvl1 > .module-nav__item:first-child > a').removeAttr('style').unbind('click').unbind('mousedown');
            $('.module-nav--more .module-nav__lvl1 > .module-nav__item:first-child').removeAttr('style').removeClass('hover').removeClass('-opened');
            $('.module-nav--more .module-nav__lvl1 > .module-nav__item:first-child > ul').removeAttr('style');
            $('.module-nav--more .module-nav__lvl1 > .module-nav__item:first-child').appendTo($('.module-nav--main .module-nav__lvl1'));
            initDesktopMenu();

            resizeNav();
          }
        }else{
          if(($('.module-nav--more .module-search').length && (getNavWidth(navLvl1Items) < $('.module-nav--main').width()))) {
            if($('.module-nav--meta').length) {
              if(navMetaBreakpoint < $(window).width()) {
                clearBodyNavClasses();

                if($('.module-nav--more .module-nav--meta').length) {
                  $('.module-nav--more .module-nav--meta').appendTo($('.page__topbar > .inner__wrap'));
                  navMetaMoved = 0;
                }

                navLvl1Items.removeAttr('style');
                $('.module-nav--more .module-search').removeAttr('style').insertAfter($('.module-nav--main'));
                $('.module-search .input__field--input > label').text($('.module-search .input__field--input > label').attr('data-text-search-closed'));

                if($('.module-nav--more').hasClass('-open')) {
                  $('.more-button').trigger('mousedown');
                }
                $('.more-button').remove();
                $('.module-nav--more').remove();
                moreMenuActive = 0;
              }
            } else {
              clearBodyNavClasses();

              if($('.module-nav--more .module-nav--meta').length) {
                $('.module-nav--more .module-nav--meta').appendTo($('.page__topbar > .inner__wrap'));
                navMetaMoved = 0;
              }

              navLvl1Items.removeAttr('style');
              $('.module-nav--more .module-search').removeAttr('style').insertAfter($('.module-nav--main'));
              $('.module-search .input__field--input > label').text($('.module-search .input__field--input > label').attr('data-text-search-closed'));

              if($('.module-nav--more').hasClass('-open')) {
                $('.more-button').trigger('mousedown');
              }
              $('.more-button').remove();
              $('.module-nav--more').remove();
              moreMenuActive = 0;
            }
          }
        }
      }
    }
  }

  function getNavWidth(nav) {
    //distance in px between nav--main and nav--more (if the value is higher, the nav will break earlier)
    var width = 16;

    $.each(nav, function() {
      width = width + $(this).outerWidth(true);
    });

    return width;
  }

  function clearBodyNavClasses() {
    $('body').removeClass('-nav-desktop-cropped').removeClass('-nav-mobile');
  }

  function appendMoreMenu() {
    moreMenuActive = 1;
    $('<div class="more-button">' + moreMenuIcon + '</div>').appendTo($('.page__header > .inner__wrap'));
    $('<div class="module-nav module-nav--more"><div class="nav__container"><ul class="module-nav__lvl1"></ul></div></div>').appendTo($('.page__header > .inner__wrap'));
    $('.module-search').prependTo($('.module-nav--more .nav__container'));
    $('.module-search .input__field--input > label').text($('.module-search .input__field--input > label').attr('data-text-search-opened'));

    $('.more-button').bind('mousedown', function() {
      if($('.module-nav--more').hasClass('-open')) {
        $('.page__header > .inner__wrap > .more-button').removeClass('-open');
        $('.module-nav--more').addClass('-fade-out');
        if($('.module-nav--more .nav__container').hasClass('-submenu')) {
          removeNavBtnBack();
        }
        window.setTimeout(function() {
          $('html, body').removeAttr('style');
          $('html, body').stop().animate({scrollTop: scrollTop}, 0);
          $('.module-nav--more').removeClass('-fade-out');
          $('.module-nav--more').removeClass('-open');
        },500);
      } else {
        if($('.page__header').hasClass('headroom--not-top')) {
          $('.page__header').removeClass('headroom--not-top');
        }
        window.setTimeout(function() {
          scrollTop = $(window).scrollTop();
          $('html, body').stop().animate({scrollTop: 0}, 0);
          $('html, body').css('width', '100%').css('height', '100%').css('overflow', 'hidden');
          $('.module-nav--more').addClass('-open');
          $('.page__header > .inner__wrap > .more-button').addClass('-open');
          setMoreMenuSize();
          if($('.module-nav--more .nav__container').hasClass('-submenu') && $('.-nav-mobile').length) {
            addNavBtnBack();
          }
        },1);
      }
    });
  }

  function setMoreMenuSize() {
    if($('.module-nav--more').length) {
      var currentWindowWidth = parseFloat($(window).width());
      var currentHeaderWidth = parseFloat($('.page__header > .inner__wrap').width());
      var newMarginLeft = (currentWindowWidth  - currentHeaderWidth) / 2 * (-1);
      var newPaddingLeft = (currentWindowWidth  - currentHeaderWidth) / 2;

      $('.module-nav--more, .module-nav--more .module-search, .module-nav--more .nav__container, .module-nav--more .module-nav__lvl1, .module-nav--more .module-nav__lvl1 > .module-nav__item, .module-nav--more .module-nav__lvl1 > .module-nav__item > a, .module-nav--more .module-nav--meta, .module-nav--more .module-nav--meta > ul, .module-nav--more .module-nav--meta > ul > .module-nav__item, .module-nav--more .module-nav--meta > ul > .module-nav__item > a').css('margin-left', newMarginLeft).css('padding-left', newPaddingLeft);
      $('.module-nav--more .module-search, .module-nav--more .module-nav__lvl1 > .module-nav__item > a').css('padding-right', newPaddingLeft);
      $('.module-nav--more, .module-nav--more .module-search, .module-nav--more .nav__container, .module-nav--more .module-nav__lvl1').css('width', $('.page').width());

      if($('.-nav-mobile').length) {
        $('.module-nav--more .module-nav__lvl2').each(function () {
          var newTop = ($(this).closest('.module-nav__item').position().top + $('.module-search').outerHeight()) * (-1);
          var newHeight = $(window).height() - $('.page__header').height();
          $(this).css('top', newTop).css('height', newHeight);
        });
        $('.module-nav--more .module-nav__lvl2 > .module-nav__item > a').css('padding-left', newPaddingLeft).css('padding-right', newPaddingLeft);
        $('.module-nav--more .module-nav__lvl3').css('padding-left', newPaddingLeft);
        $('.module-nav--more .module-nav__lvl3 > .module-nav__item > a').css('padding-right', newPaddingLeft);
      } else {
        $('.module-nav__lvl2').removeAttr('style');
        $('.module-nav__lvl2 > .module-nav__item > a').removeAttr('style');
        $('.module-nav__lvl3').removeAttr('style');
        $('.module-nav__lvl3 > .module-nav__item > a').removeAttr('style');
      }
    }
  }

  var scrollTop;
  function initLanguageNavMobile() {
    $('.module-nav--lang .current__flag').unbind('mousedown').unbind('click');
    $('.module-nav--lang .current__flag').bind('mousedown', function() {
      var navLang = $(this).closest('.module-nav--lang');
      if(!navLang.hasClass('-open')) {
        if($('.page__header').hasClass('headroom--not-top')) {
          $('.page__header').removeClass('headroom--not-top');
        }
        window.setTimeout(function() {
          scrollTop = $(window).scrollTop();
          $('html, body').stop().animate({scrollTop: 0}, 0);
          $('html, body').css('width', '100%').css('height', '100%').css('overflow', 'hidden').css('pointer-events', 'none');
          document.ontouchmove = function(e) { e.preventDefault(); };
          navLang.addClass('-open');
        },1);
      }else{
        navLang.addClass('-open');
        window.setTimeout(function() {
          $('html, body').removeAttr('style');
          $('html, body').stop().animate({scrollTop: scrollTop}, 0);
          document.ontouchmove = function() { return true; };
          navLang.removeClass('-open').removeClass('-close');
        }, 0);
      }
    });

    $('.module-nav--lang .available__label .close-icon').bind('mousedown', function() {
      var navLang = $(this).closest('.module-nav--lang');
      if(navLang.hasClass('-open')) {
        navLang.addClass('-close');
        window.setTimeout(function() {
          $('html,body').removeAttr('style');
          $('html, body').stop().animate({scrollTop: scrollTop}, 0);
          document.ontouchmove = function() { return true; };
          navLang.removeClass('-open').removeClass('-close');
        }, 0);
      }
    });
  }

  function initLanguageNavDesktop() {
    $('.module-nav--lang .current__flag').unbind('mousedown').unbind('click');
    $('.module-nav--lang .current__flag').bind('mousedown', function() {
      var navLang = $(this).closest('.module-nav--lang');
      if(!navLang.hasClass('-open')) {
        navLang.addClass('-open');
      }else{
        navLang.removeClass('-open');
      }
    });
  }

  var hrjs;
  function initHeadroomJs() {
    var hrjsEl = document.querySelector('.page__header');
    hrjs  = new Headroom(hrjsEl, {
      offset : $('.page__topbar').height() + $('.page__header').height(),
      tolerance : 10,
      classes : {
        initial : "hrjs",
        pinned : "hrjs--pinned",
        unpinned : "hrjs--unpinned",
        top : "hrjs--top",
        notTop : "hrjs--not-top",
        bottom : "hrjs--bottom",
        notBottom : "hrjs--not-bottom"
      },
      onUnpin : function() {
        if($('.module-nav--more').hasClass('-open')) {
          $('.more-button.-open').trigger('mousedown');
        }

        $('.touchevents .module-nav__lvl1 > .module-nav__item.-opened > a').trigger('mousedown');
      }
    });

    hrjs.init();
  }

  function checkHrjsState() {
    var pageTopbarHeight = $('.page__topbar').height();
    if($(window).scrollTop() >= pageTopbarHeight) {
      $('.page__header').addClass('-fixed');
    }else{
      if($('body').hasClass('-nav-mobile')) {
        $('.page__header').addClass('-fixed');
      }else{
        $('.page__header').removeClass('-fixed');
      }
    }

    var pageHeaderHeight = $('.page__header').height();
    $('.page__content').css('padding-top', pageHeaderHeight);
  }

  function setMobileNavHeight() {
    if($('body').hasClass('-nav-mobile')) {
      var availHeight = $(window).height() - ($('.module-nav--more .module-search').outerHeight() + $('.module-nav--more .module-nav--meta').outerHeight() + $('.page__header').outerHeight());

      $('.module-nav--more .module-nav__lvl1').css('min-height', $('.module-nav--more .module-nav__lvl1').outerHeight()).css('height', availHeight);

      availHeight = $(window).height() - $('.page__header').outerHeight();

      $('.module-nav--more .nav__container').css('height', availHeight);
    }else{
      $('.module-nav--more .module-nav__lvl1').css('min-height', 'auto').css('height', 'auto');
      $('.module-nav--more .nav__container').css('height', 'auto');
    }
  }

  function initFooterNavAccordion() {
    $('.module-nav.module-nav--footer .nav__headline').each(function () {
      $(this).on('mousedown', function () {
        if(currentBreakpoint == 0) {
          var $nav = $(this).closest('.module-nav');
          if (!$nav.hasClass('-open')) {
            $nav.addClass('-open');
          } else {
            $nav.removeClass('-open');
          }
        }
      });
    });
  }

  function initBreadcrumb() {
    $('.page__footer .footer__breadcrumb .nav__crumb').eq(0).addClass('nav__crumb--first');

    var rootlevel = $('.page__footer .footer__breadcrumb .nav__crumb').length;

    if(rootlevel == 1) {
      $('.page__footer .footer__breadcrumb .nav__crumb').eq(0).addClass('-root');
    }

    if(rootlevel > 3) {
      var cycleIndex = rootlevel - 3;

      for(var i = 1; i <= cycleIndex; i++) {
        $('.page__footer .footer__breadcrumb .nav__crumb').eq(i).addClass('nav__crumb--hidden');

        if(i == cycleIndex) {
          var title = $('.page__footer .footer__breadcrumb .nav__crumb').eq(i).find('a').attr('title');
          var link = $('.page__footer .footer__breadcrumb .nav__crumb').eq(i).find('a').attr('href');

          $('.page__footer .footer__breadcrumb .nav__crumb').eq(i).after('<span class="nav__crumb"><span class="crumb__title"><a href="' + link + '" title="' + title + '">...</a></span></span>');
        }
      }
    }
  }
  

  function addNavBtnBack() {
    $('.page__header .inner__wrap').prepend('<div class="module-nav-more-btn-back"><div class="text">' + $('.module-nav--main').attr('data-text-nav-btn-back') + '</div></div>');
    $('.module-nav--lang, .module-logo').css('opacity', 0).css('width', 0).css('pointer-events', 'none').css('overflow', 'hidden');

    $('.module-nav-more-btn-back').on('mousedown', function () {
      $('.module-nav--more .nav__container').removeClass('-submenu');
      $('.module-nav--more .module-nav__item').removeClass('-opened');

      removeNavBtnBack();
    });
  }

  function removeNavBtnBack() {
    $('.module-nav-more-btn-back').remove();
    $('.module-nav--lang, .module-logo').removeAttr('style');
  }

  $(document).ready(function() {
    checkNavActiveState();
    setNavMetaBreakpoint();
    initDesktopMenu();
    initMobileMenu();
    initLanguageNavDesktop();
    initHeadroomJs();
    checkHrjsState();
    resizeNav();
    setMoreMenuSize();
    setMobileNavHeight();
    initFooterNavAccordion();
    initBreadcrumb();
  });

  if(document.readyState === 'complete') {
    setMoreMenuSize();
    checkHrjsState();
    setMobileNavHeight();
  } else {
    $(window).on('load', function() {
      setMoreMenuSize();
      checkHrjsState();
      setMobileNavHeight();
    });
  }

  $(window).resize(function() {
    setNavMetaBreakpoint();
    resizeNav();
    setMoreMenuSize();
    checkHrjsState();
    setMobileNavHeight();
  });

  var rtime;
  var timeout = false;
  var delta = 200;
  $(window).resize(function() {
    // Trigger the resizeend function
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  // Initialize a resizeend function
  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;

      // Functions to fire after resize has stopped
      setNavMetaBreakpoint();
      resizeNav();
      setMoreMenuSize();
      checkHrjsState();
      setMobileNavHeight();
    }
  }

  $(window).on( "orientationchange", function() {
    setNavMetaBreakpoint();
    resizeNav();
    setMoreMenuSize();
    checkHrjsState();
    setMobileNavHeight();
    $(window).trigger('resize');
  });

  $(window).scroll(function() {
    checkHrjsState();
  });

  return communiacsModules;

})( communiacsModules || {} );
