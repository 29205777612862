var communiacsModules = ( function( communiacsModules ) {
  'use strict';

  if(!$) {
    throw new Error('jQuery is required.');
  }

  function initFaqItems() {
    $('.module-faq .faq__item:not(.-no-text) .item__title').each(function () {
      $(this).bind('mousedown', function () {
        if(!$(this).closest('.faq__item').hasClass('-opened')){
          $(this).closest('.faq__item').addClass('-opened');
        } else {
          $(this).closest('.faq__item').removeClass('-opened');
        }
      });
    });
  }

  $(document).ready(function() {
    initFaqItems();
  });

  return communiacsModules;

})( communiacsModules || {} );
