var communiacsModules = ( function( communiacsModules ) {
    'use strict';

    if(!$) {
        throw new Error('jQuery is required.');
    }

    function initColBreakout () {
        $('.col-wrapper.-max-width-limited.-breakout-left > .col-one').each(function () {
            setColWidth($(this), 'margin-left');
        });

        $('.col-wrapper.-max-width-limited.-breakout-right > .col-two').each(function () {
            setColWidth($(this), 'margin-right');
        });
    }

    function setColWidth ($col, type) {
        // clear Styles
        $col.removeAttr('style');

        // calc new width
        var outerMargin,
            colWidth,
            colWrapperWidth,
            sectionWidth,
            newWidth;

        colWidth = $col.outerWidth();
        colWrapperWidth = $col.closest('.col-wrapper').width();
        sectionWidth = $col.closest('.section-row').width();
        outerMargin = (sectionWidth - colWrapperWidth) / 2;
        if (colWidth != colWrapperWidth) {
            newWidth = outerMargin + colWidth;

            // add new style
            $col.css(type, outerMargin * (-1)).css('width', newWidth);
        } else {
            newWidth = sectionWidth;

            // add new style
            $col.css('margin-left', outerMargin * (-1)).css('margin-right', outerMargin * (-1)).css('width', newWidth);
        }
    }

    $(document).ready(function() {
        initColBreakout();
    });

    if(document.readyState === 'complete') {

    }else{
        $(window).on('load', function() {

        });
    }

    var rtime;
    var timeout = false;
    var delta = 150;
    $(window).resize(function() {
        // Functions to fire on resize
        initColBreakout();

        // Trigger the resizeend function
        rtime = new Date();
        if (timeout === false) {
            timeout = true;
            setTimeout(resizeend, delta);
        }
    });

    // Initialize a resizeend function
    function resizeend() {
        if (new Date() - rtime < delta) {
            setTimeout(resizeend, delta);
        } else {
            timeout = false;

            // Functions to fire after resize has stopped

        }
    }

    return communiacsModules;

})( communiacsModules || {} );
