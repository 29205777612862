var communiacsModules = ( function( communiacsModules ) {
  'use strict';

  if(!$) {
    throw new Error('jQuery is required.');
  }

  function updateCurrentBreakpoint() {
    currentBreakpoint = window.getComputedStyle(document.body,':after').getPropertyValue('content');
    currentBreakpoint = parseInt(currentBreakpoint.split('-')[1]);
  }

  function activateTransitions() {
    // Activates the Transitions by removing the body tag
    $('body').removeClass('transition-preload');
  }

  function calcSusyWidths(action, columns) {
    var columnWidth = (1.564 * 100) / ((12 * 1.564) + 11);
    var gutterWidth = columnWidth / 1.564;

    switch(action) {
      case 'span':
        return ((columns * columnWidth) + ((columns - 1) * gutterWidth));
        break;
      case 'gutter':
        return gutterWidth;
        break;
    }
  }

  communiacsModules.calcSusyWidths = calcSusyWidths;

  function initBackToTop() {
    $('.module-backtotop').bind('mousedown', function() {
      $('html, body').animate({scrollTop: 0}, 1000);
    });
  }

  function setHeaderLoadedClass() {
    $('body').addClass('-headerloaded');
  }

  function setSidebarAttributes() {
    $('.col-wrapper.-break-out-from-sidebar').each(function () {
      var newW = $(this).parents('.col-wrapper').width();
      var newMargin = ($(this).closest('.col-wrapper').width() - $(this).closest('.col-one').width()) * (-1);
      $(this).css('width', newW).css('margin-right', newMargin);
    });
  }

  $(document).ready(function() {
    updateCurrentBreakpoint();
    initBackToTop();
    setHeaderLoadedClass();
    setSidebarAttributes();
  });

  if(document.readyState === 'complete') {
    activateTransitions();
    updateCurrentBreakpoint();
    setSidebarAttributes();
  }else{
    $(window).on('load', function() {
      activateTransitions();
      updateCurrentBreakpoint();
      setSidebarAttributes();
    });
  }

  $(window).resize(function() {
    updateCurrentBreakpoint();
    setSidebarAttributes();
  });

  return communiacsModules;

})( communiacsModules || {} );
