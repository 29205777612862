var communiacsModules = ( function( communiacsModules ) {
  'use strict';

  if(!$) {
    throw new Error('jQuery is required.');
  }

  function initFormFields () {
    $('form .form-group .input').each(function () {
      // set active class for input and textarea if they are filled
      $(this).find('input, textarea, select').each(function () {
        checkVal($(this));

        $(this).on('focusout', function () {
          checkVal($(this));
        });
      });

      $(this).find('input[required], textarea[required]').each(function () {
        $(this).closest('.input').addClass('-required');
      });

      $(this).find('input:not([required]), textarea:not([required])').each(function () {
        $(this).closest('.input').addClass('-optional');
      });

      $(this).find('input, textarea, select').trigger('focusout');
    });

    $('form .form-group .checkbox, .form .form-group .checkbox').each(function () {
      // set/remove checked class for checkboxes
      $(this).on('change', function () {
        if ($(this).find('input[type="checkbox"]').prop('checked')) {
          $(this).addClass('-checked');
        } else {
          $(this).removeClass('-checked');
        }
      });

      $(this).trigger('change');
    });

    $('form .form-group .radio input[type=radio]').each(function () {
      // set/remove checked class for checkboxes
      $(this).on('change', function () {
        if ($(this).prop('checked')) {
          $(this).closest('.radio').addClass('-filled');
          $(this).closest('.form-group').addClass('-filled');
          $(this).addClass('-filled');
        }else{
          $(this).closest('.radio').removeClass('-filled');
          $(this).removeClass('-filled');
        }
        $(this).closest('.radiobuttons').find('input:not(:checked)').closest('.radio').removeClass('-filled');
        $(this).closest('.radiobuttons').find('input:not(:checked)').removeClass('-filled');
        initContactForm($(this));
      });

      $(this).trigger('change');
    });


  }

  function initContactForm (el) {
    let contactForm = $('#ContactForm')
    if (el.hasClass('-filled')) {
      let emailInput = contactForm.find('.email')
      let phoneInput = contactForm.find('.phone')
      if (el[0].value === 'email') {
        emailInput.attr('required', 'required')
        // emailInput.closest('.input').addClass('-required')
        emailInput.closest('.input').removeClass('-optional')
        // phoneInput.closest('.input').removeClass('-required')
        phoneInput.closest('.input').addClass('-optional')
        phoneInput.removeAttr('required')
      }
      if (el[0].value === 'phone') {
        phoneInput.attr('required', 'required')
        // phoneInput.closest('.input').addClass('-required')
        phoneInput.closest('.input').removeClass('-optional')
        // emailInput.closest('.input').removeClass('-required')
        emailInput.closest('.input').addClass('-optional')
        emailInput.removeAttr('required')
      }
    }
  }

  // Helper Function
  function checkVal($el) {
    if ($el.val() !== '') {
      $el.addClass('-filled').closest('.form-group').removeClass('-error');
    } else {
      $el.removeClass('-filled');
    }
  }

  $(document).ready(function() {
    initFormFields();
  });

  return communiacsModules;

})( communiacsModules || {} );
