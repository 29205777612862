var communiacsModules = ( function( communiacsModules ) {
    'use strict';

    if(!$) {
        throw new Error('jQuery is required.');
    }

    function initAnchorLinks() {
        $('a[href*="#"]').click(function(e) {
            if($($(this).prop('hash')).length) {
                e.preventDefault();

                $('html, body').stop().animate({scrollTop: parseInt($($(this).prop('hash')).offset().top)}, 500);
            }
        });
    }

    function initInitialHashLinks() {
        if(window.location.hash) {
            if($(window.location.hash).length) {
                window.setTimeout(function() {
                    $('.page').scrollTop(0);
                    $('html, body').stop().animate({scrollTop: parseInt($(window.location.hash).offset().top)}, 500);
                }, 100);
            }
        }
    }

    $(document).ready(function () {
        initAnchorLinks();
    });

    if(document.readyState === 'complete') {
        initInitialHashLinks();
    } else {
        $(window).on('load', function() {
            initInitialHashLinks();
        });
    }

    return communiacsModules;

})( communiacsModules || {} );
